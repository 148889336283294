<template>
	<w-btn
			v-if="canRotate"
			:flat="flat"
			:icon="icon"
			:loading="loading"
			mini
			@click="rotateDocument()"
	>
		{{ label }}
	</w-btn>
</template>

<script>
import DocumentVersionsModuleGuard from "@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard";
import RotateDocumentMixin from "@/components/Documents/Actions/Rotate/RotateDocumentMixin";

export default {
	name: 'RotateDocumentButton',
	mixins: [
		DocumentVersionsModuleGuard,
		RotateDocumentMixin
	],
	props: {
		flat: {
			type: Boolean,
			required: false,
			default: true
		},
		value: {
			type: [Object, Array],
			required: false,
			default: null
		},
		direction: {
			type: String,
			required: true
		}
	},
	data () {
		return {
			loading: false
		}
	},
	computed: {
		nodes () {
			return Array.isArray(this.value) ? this.value : [this.value] || []
		},
		label () {
			return this.$tc(`actions.rotate_document_${this.direction}`, this.nodes.length)
		},
		icon () {
			return `rotate_${this.direction}`
		}
	},
}
</script>
