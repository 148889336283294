import { canRotateNode } from "@/components/Documents/Actions/Rotate/canRotateNode";

export default {
	computed: {
		canRotate () {
			return this.nodes.length > 0 && this.nodes.every(canRotateNode)
		},
	},
	methods: {
		async rotateDocument (direction = null) {
			if (! this.canRotate || this.loading) {
				return;
			}
			const dir = direction ?? this.direction;
			this.loading = true
			try {
				await Promise.all(
					this.nodes.map(node => this.service.updateDocumentVersion(
						this.vendorId,
						node.id,
						node.latestVersion,
						null,
						{
							angle: dir === 'left' ? 270 : 90
						}
					))
				);
				this.eventBus.emit(this.events.preview.ROTATE_DOCUMENT);
			} catch (error) {
				this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('api.errors.node.rotate'))
			} finally {
				this.loading = false;
			}
		}
	}
}
