import { mimetypes } from "@/helpers/files";

export function canRotateNode (node) {
	return !node.is_readonly &&
		!node.has_signatories &&
		!node.deleted_at &&
		[
			mimetypes.pdf,
			mimetypes.gif,
			mimetypes.jpe,
			mimetypes.jpg,
			mimetypes.jpeg,
			mimetypes.png,
			mimetypes.svg
		].includes(node.type);
}
